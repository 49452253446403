import React from "react";
import { Element } from "react-scroll";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/common/Hero";

import imgL from "../assets/image/png/ian-transparent.png";
const IndexPage = () => {
  return (
    <>
      <PageWrapper>
        <Hero
          username="ian"
          fullname="Ian Sulcer"
          homegym="10th Planet San Mateo"
          pricing={<a>$100 for 1 student or <br/>2 students $65 each</a>}
          profile={`10th Planet San Mateo Brown Belt, Ian is a takedown and fundamentals specialist. You can always count on Ian to provide creative insights with the sharp breakdowns of technique, whether you're experienced, high-level or it's your first day.`}
          imgL={imgL}
          calendly={"https://calendly.com/ian-sulcer/1h"}
        />

      </PageWrapper>
    </>
  );
};
export default IndexPage;
